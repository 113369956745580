import { SET_RESULTS, SET_SELECTED_RESULT } from "../action-creators/types";

const initialState = {
    results: [],
    selectedResult: {},
};

const resultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RESULTS:
            return { ...state, results: action.payload };
        case SET_SELECTED_RESULT:
            return { ...state, selectedResult: action.payload };
        default:
            return state;
    }
};

export default resultsReducer;
