import React, { useState } from "react";
import SearchTextField from "../../components/SearchTextField";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
// import Autocomplete from '@material-ui/lab/Autocomplete';

const PlacesSearchBar = (props) => {
    const { handleSearch, requestLoading } = props;
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({
        latitude: null,
        longitude: null,
    });

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setAddress(value);
        setCoordinates({
            latitude: latLng.lat,
            longitude: latLng.lng,
        });
    };

    return (
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="autocomplete-root">
                    {/* <p>Latitude: {coordinates.latitude}</p>
                    <p>Longitude: {coordinates.longitude}</p> */}
                    <SearchTextField
                        {...getInputProps({
                            placeholder: "Ingresa tu dirección",
                            handleSearch: () => handleSearch(coordinates),
                            requestLoading: requestLoading,
                        })}
                    />
                    <div style={{ position: "relative" }} className="autocomplete-dropdown-container">
                        {/* {loading ? <div>loading...</div> : null} */}
                        <div style={{ position: "absolute", zIndex: 2, width: "100%" }}>
                            {suggestions.map((suggestion) => {
                                const style = {
                                    backgroundColor: suggestion.active ? "#1AFCA5" : "white",
                                    padding: 10,
                                    color: "#2D2F69",
                                };
                                return (
                                    <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                        {suggestion.description}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default PlacesSearchBar;
