import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import logo_v from "../../images/logo_v.png";

const useStyles = makeStyles((theme, matches) => ({
    container: {
        backgroundColor: "White",
        width: "100%",
        paddingBottom: "3%",
        paddingTop: "3%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-evenly",
    },
    containerSmall: {
        backgroundColor: "White",
        width: "100%",
        paddingBottom: "3%",
        paddingTop: "3%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
    },
    childDivs: {
        maxWidth: "25%",
        // backgroundColor: "red",
    },
    childDivsSmall: {
        width: "95%",
        // backgroundColor: "purple",
        marginBottom: "3%",
        textAlign: "center",
    },

    text1: {
        fontFamily: "Nunito",
        color: "#79797C",
        fontSize: 24,
        fontWeight: "bold",
    },
    text2: {
        fontFamily: "Roboto",
        color: "#79797C",
        fontSize: 13,
        fontWeight: "light",
    },
}));

const Footer = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const classes = useStyles(theme, matches);
    return (
        <div className={matches ? classes.container : classes.containerSmall}>
            <div className={matches ? classes.childDivs : classes.childDivsSmall}>
                <img src={logo_v} alt="logo" style={{ width: 80 }} />
            </div>
            <div className={matches ? classes.childDivs : classes.childDivsSmall}>
                <p className={classes.text1}>¿Quienes somos?</p>
                <p className={classes.text2}>
                    Somos un equipo de emprendedores colombianos y queremos mejorar la búsqueda y adquisición de
                    servicios infantiles en el mundo. Nuestra meta es crear una comunidad que permita generar
                    realimentación confiable de padres para padres.
                </p>
            </div>

            <div className={matches ? classes.childDivs : classes.childDivsSmall}>
                <p className={classes.text1}>Acerca de</p>
                <p className={classes.text2}>Política de privacidad</p>
                <p className={classes.text2}>Política de cookies</p>
                <div className={classes.text2}>
                    Icons and Ilustrations made by{" "}
                    <a className={classes.text2} href="https://www.freepik.com" title="Freepik">
                        Freepik
                    </a>{" "}
                </div>
                <div className={classes.text2}>
                    from{" "}
                    <a className={classes.text2} href="https://www.flaticon.com/" title="Flaticon">
                        www.flaticon.com
                    </a>
                </div>
            </div>

            <div className={matches ? classes.childDivs : classes.childDivsSmall}>
                <p className={classes.text1}>Contacto</p>
                <p className={classes.text2}>info@kidssey.com</p>
                <p className={classes.text2}>+57 3136434028</p>
            </div>
        </div>
    );
};

export default Footer;
