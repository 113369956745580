import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
    root: {
        // backgroundImage: `url(${background2})`,
        // backgroundColor:"#2D2F69",
        // backgroundColor: "#191D3C",
        backgroundColor: "#fefefe",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        widht: "100%",
        display: "flex",
        alignItems: "strech",
        justifyContent: "center",
        flexDirection: "row",
    },
    rootSmall: {
        backgroundColor: "#fefefe",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        widht: "100%",
        display: "flex",
        alignItems: "strech",
        justifyContent: "center",
        flexDirection: "column",
    },
    FilterBtnSmall: {
        backgroundColor: "#27698C",
        color: "white",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
        width: "90%",
        margin: "auto",
        marginTop: "3%",
    },
    textName: {
        fontFamily: "Tinos, serif",
        color: "#191D3C",
        fontWeight: "bold",
        fontSize: 30,
        padding: "1%",
    },
    container: {
        width: "100%",
        height: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    searchContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        flexDirection: "column",
        width: "70%",
        height: "100%",
    },
    imageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "30%",
        height: "100%",
    },
    text1: {
        fontFamily: "Vidaloka, serif",
        color: "white",
        fontSize: 34,
    },
    text2: {
        fontFamily: "Open Sans, sans-serif",
        color: "white",
        fontSize: 17,
    },
    divCards: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingTop: "5%",
        paddingLeft: "3%",
        paddingRight: "3%",
        flex: 1,
        width: "100%",
    },
    divNoResult: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        flexDirection: "column",
        flex: 1,
        // backgroundColor: "red",
        paddingTop: "20%",
    },
    divNoResultSmall: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        // flex: 1,
        height: 500,
        paddingTop: "20%",
    },
    noResultText: {
        fontFamily: "Nunito",
        color: "#F44D75",
        textAlign: "center",
    },
    divFilters: {
        backgroundColor: "white",
        paddingTop: "3%",
        paddingBottom: "1%",
    },
    divHeader: {
        backgroundColor: "#6E57F7",
        width: "100%",
        height: 80,
        webkitBoxShadow: " 0px 9px 10px -3px rgba(156,156,156,0.71)",
        mozBoxShadow: " 0px 9px 10px -3px rgba(156,156,156,0.71)",
        boxShadow: "0px 9px 10px -3px rgba(156,156,156,0.71)",
        zIndex: 10,
    },
    divSlider: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(5),
        marginTop: theme.spacing(2),
    },
    circularProgress: {
        color: "#6E57F7",
    },
}));

export default styles;
