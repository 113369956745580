import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import avatar from "../../images/baby.png";
import avatar2 from "../../images/girl.png";
import avatar3 from "../../images/student.png";
import avatar4 from "../../images/boy.png";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
    capsule: {
        borderRadius: 5,
        // border: "2px solid #D96B2B",
        padding: 1,
        width: "23%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    capsuleText: {
        fontSize: 10,
        color: "gray",
        width: "100%",
        fontFamily: "Roboto",
    },
    text1: {
        fontFamily: "Nunito",
        color: "#262A54",
        fontSize: 17,
        fontWeight: "bold",
    },
    icon: {
        width: 30,
        height: 30,
    },
}));

export default function AgeRange(props) {
    const { age_range } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography id="discrete-slider-restrict" gutterBottom className={classes.text1}>
                Etapas:
            </Typography>
            <div className={classes.container}>
                {age_range.length > 0
                    ? age_range.map((range, index) => {
                          return (
                              <div className={classes.capsule} key={index}>
                                  <img
                                      src={
                                          range === 1
                                              ? avatar
                                              : range === 2
                                              ? avatar2
                                              : range === 3
                                              ? avatar3
                                              : range === 4
                                              ? avatar4
                                              : null
                                      }
                                      alt=""
                                      className={classes.icon}
                                  />
                                  <div>
                                      <p className={classes.capsuleText}>
                                          {range === 1
                                              ? "De 0 a 3 "
                                              : range === 2
                                              ? "De 3 a 6 "
                                              : range === 3
                                              ? "De 6 a 12 "
                                              : range === 4
                                              ? "Más de 12 "
                                              : null}
                                      </p>
                                      <p className={classes.capsuleText}>años</p>
                                  </div>
                              </div>
                          );
                      })
                    : null}
            </div>
        </div>
    );
}
