import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import paleta from "../../images/paleta.png";
import bombillo from "../../images/bombillo.png";
import libros from "../../images/libros.png";
import { BlockRounded } from "@material-ui/icons";

export default function ButtonHome(props) {
    const { text, onClick, selected } = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const useStyles = makeStyles((theme) => ({
        margin: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            display: "flex",
        },
        text: {
            fontFamily: "Nunito",
            fontWeight: selected ? "bold" : "regular",
            marginLeft: 10,
            color: selected ? "#737272" : "#707070",
            "&:hover": {
                color: "#fff",
            },
        },
    }));
    const BootstrapButton = withStyles({
        root: {
            boxShadow: "none",
            textTransform: "none",
            fontSize: 13,
            padding: "6px 20px",
            lineHeight: 1.5,
            color: "white",
            backgroundColor: selected ? "#1AFCA5" : "transparent",

            border: selected ? "0px" : " 1px solid #bcbcbc",
            borderRadius: 40,
            // padding: "10px 0px 10px 20px",

            "&:hover": {
                backgroundColor: "#6E57F7",
                // borderColor: "#FFFFFF",
                // boxShadow: "none",
                color: "#fff",
            },
        },
    })(Button);

    const classes = useStyles();
    return (
        <div>
            <BootstrapButton
                variant="contained"
                color="primary"
                size="medium"
                disableRipple
                className={classes.margin}
                onClick={onClick}
            >
                <Tooltip
                    TransitionComponent={Zoom}
                    title={
                        text === "Colegios"
                            ? "Colegios"
                            : text === "Centros deportivos"
                            ? "Centros deportivos"
                            : text === "Escuelas de arte"
                            ? "Escuelas de arte"
                            : null
                    }
                >
                    {text === "Colegios" ? (
                        <img src={libros} alt="libros" style={{ width: "25%" }} />
                    ) : text === "Centros deportivos" ? (
                        <img src={bombillo} alt="bombillo" style={{ width: "20%" }} />
                    ) : text === "Escuelas de arte" ? (
                        <img src={paleta} alt="paleta" style={{ width: "20%" }} />
                    ) : null}
                </Tooltip>

                {matches ? <p className={classes.text}>{text}</p> : null}
            </BootstrapButton>
        </div>
    );
}
