import React, { useEffect, useState } from "react";
import styles from "./styles";
import { useHistory, useParams } from "react-router-dom";
import api from "../../config/services/api";
import { FIND_SERVICE_BY_ID } from "../../config/services/requests";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../config/state";
import Header from "../../components/Header";
import ContactCard from "../../components/ContactCard";
import MyCarousel from "../../components/MyCarousel";
import { Typography } from "@material-ui/core";
import GeneralRatingStars from "../../components/GeneralRatingStars";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Service = () => {
    const classes = styles();
    const { id } = useParams();
    const history = useHistory();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const selectedResultState = useSelector((state) => state.results?.selectedResult);
    const dispatch = useDispatch();
    const { setSelectedResult } = bindActionCreators(actionCreators, dispatch);

    const [data, setData] = useState({});
    // console.log("dataService", data.score);
    useEffect(() => {
        const getService = async () => {
            try {
                const res = await api.get(`${FIND_SERVICE_BY_ID}?id=${id}`);
                if (res.data) {
                    setData(res.data);
                    setSelectedResult(res.data);
                }
            } catch (error) {
                history.push("/");
            }
        };

        if (selectedResultState && Object.keys(selectedResultState).length > 0) {
            setData(selectedResultState);
        } else {
            getService();
        }
    }, [selectedResultState, id, history, setSelectedResult]);

    return (
        <div>
            <Header />
            <div className={classes.root}>
                {Object.keys(data).length > 0 && (
                    <div className={classes.headerInfo}>
                        <img src={data.logo} alt="" className={classes.icon} />
                        <div>
                            <p className={classes.text1}>{data.name}</p>
                            <Typography color="textSecondary" className={classes.distanceText}>
                                {data.address}
                            </Typography>
                            <GeneralRatingStars value={data.score} />
                        </div>
                    </div>
                )}
                <div className={matches ? classes.container : classes.containerSmall}>
                    {/* <img src={schoolPhoto1} alt="" className={classes.images} /> */}
                    {Object.keys(data).length > 0 ? (
                        <>
                            <div className={matches ? classes.carrouselContainer : null}>
                                <MyCarousel images={data.images} />
                            </div>
                            <div className={matches ? classes.contactCardContainer : null}>
                                <ContactCard
                                    id={id}
                                    avatar={data.logo}
                                    name={data.name}
                                    address={data.address}
                                    description={data.description}
                                    price_range={data.price_range}
                                    score={data.score}
                                    email={data.email}
                                    age_range={data.age_range}
                                    cellphone={data.cellphone}
                                    distance={data.distance}
                                    inclusivity={data.inclusivity}
                                    tags={data.tags}
                                    type={data.type}
                                    web_page={data.web_page}
                                    location={data.location}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.carouselShadow}></div>
                            <div></div>
                        </>
                    )}
                </div>
                {/* {id} */}
            </div>
        </div>
    );
};

export default Service;
