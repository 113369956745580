import { SET_RESULTS, SET_SELECTED_RESULT } from "./types";

export const setResults = (results) => {
    return (dispatch) => {
        dispatch({
            type: SET_RESULTS,
            payload: results,
        });
    };
};

export const setSelectedResult = (result) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_RESULT,
            payload: result,
        });
    };
};
