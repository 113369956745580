import { Carousel } from "react-carousel-minimal";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function MyCarousel(props) {
    const { images } = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const captionStyle = {
        fontSize: "2em",
        fontWeight: "bold",
    };
    const slideNumberStyle = {
        fontSize: "20px",
        fontWeight: "bold",
    };
    return (
        <div className="App">
            <div style={{ textAlign: "center" }}>
                <div
                    style={{
                        padding: matches ? "0 20px" : null,

                        // width: 900,
                    }}
                >
                    <Carousel
                        data={images}
                        time={5000}
                        width={matches ? "850px" : "inherit"}
                        height="500px"
                        captionStyle={captionStyle}
                        radius="10px"
                        slideNumber={true}
                        slideNumberStyle={slideNumberStyle}
                        captionPosition="bottom"
                        automatic={true}
                        dots={true}
                        pauseIconColor="white"
                        pauseIconSize="40px"
                        slideBackgroundColor="darkgrey"
                        slideImageFit="cover"
                        // thumbnails={true}
                        // thumbnailWidth="100px"
                        style={{
                            textAlign: "center",
                            maxWidth: matches ? "850px" : "inherit",
                            maxHeight: "500px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: matches ? null : "3%",
                            marginTop: matches ? null : "3%",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default MyCarousel;
