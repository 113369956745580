import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from "@material-ui/core/Checkbox";
import RatingCoins from "../RatingCoins";
import GeneralRatingStars from "../GeneralRatingStars";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    formControl: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    text1: {
        fontFamily: "Nunito",
        color: "#262A54",
        fontSize: 17,
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
    },
    separator: {
        width: "90%",
        border: "1px solid lightgray",
        alignSelf: "center",
        marginTop: theme.spacing(1.5),
    },
    checkbox: {
        padding: 0,
        marginRight: theme.spacing(1),
    },
    formControlLabel: {
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
    },
    labelText: {
        fontFamily: "Roboto",
        fontSize: 15,
    },
}));

const MyCheckBox = withStyles({
    root: {
        color: "#6E57F7",
        "&$checked": {
            color: "#6E57F7",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckBoxFilter(props) {
    // const { title, labels, values, onSelect } = props;
    const { title, data, onSelect, filterName, priceFilter, scoreFilter } = props;
    const classes = useStyles();
    // const [data, setData] = useState(values.reduce((o, key) => ({ ...o, [key]: false }), {}));
    const [tagValue, setTagValue] = useState(data.reduce((o, key) => ({ ...o, [key.tag]: key.value }), {}));

    const handleChange = (event) => {
        const newTagValue = { ...tagValue, [event.target.name]: event.target.checked };
        setTagValue(newTagValue);

        const keys = Object.keys(newTagValue).filter((k) => newTagValue[k]);
        const result = keys.join("-");

        onSelect(filterName, result);
    };

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.text1}>
                    {title}
                </FormLabel>
                <FormGroup>
                    {data.map((item, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                className={classes.formControlLabel}
                                control={
                                    <MyCheckBox
                                        checked={tagValue[`${item.tag}`]}
                                        onChange={handleChange}
                                        name={item.tag}
                                        className={classes.checkbox}
                                    />
                                }
                                label={
                                    priceFilter ? (
                                        <RatingCoins value={parseInt(item.label)} />
                                    ) : scoreFilter ? (
                                        <GeneralRatingStars value={parseInt(item.label)} />
                                    ) : (
                                        <p className={classes.labelText}>{item.label}</p>
                                    )
                                }
                            />
                        );
                    })}
                </FormGroup>
                {/* <FormHelperText>Be careful</FormHelperText> */}
            </FormControl>
            <div className={classes.separator}></div>
        </div>
    );
}
