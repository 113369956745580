import React from "react";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const SimpleRating = (props) => {
    const { value } = props;

    return (
        <Rating
            name="read-only"
            value={value}
            readOnly
            precision={0.5}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
        />
    );
};

export default SimpleRating;
