import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import RatingCoins from "../RatingCoins";
import AgeRange from "../AgeRange";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import InlcusivityIcons from "../InclusivityIcons";
import useGAEventsTracker from "../../hooks/useGAEventsTracker";
import api from "../../config/services/api";
import { CREATE_CONTACT } from "../../config/services/requests";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    icon: {
        width: 50,
        height: 50,
        // backgroundColor: "red",
    },
    text1: {
        fontFamily: "Nunito",
        color: "#262A54",
        fontSize: 17,
        fontWeight: "bold",
    },
    twoColumns: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // backgroundColor: "purple",
    },
    div2: {
        // width: "50%",
        width: "fit-content",
    },
    text2: {
        fontFamily: "Roboto",
        // color: "white",
        fontSize: 14,
        // textAlign: "center",
        // margin: "auto",
    },
    contactBtn: {
        backgroundColor: "#6E57F7",
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
}));

export default function ContactCard(props) {
    const classes = useStyles();
    const {
        id,
        avatar,
        name,
        address,
        description,
        price_range,
        score,
        email,
        age_range,
        cellphone,
        distance,
        inclusivity,
        tags,
        type,
        web_page,
        location,
    } = props;

    const GAEventsTracker = useGAEventsTracker("Result Service Card");

    const handleContact = async () => {
        const today = new Date();
        try {
            api.post(CREATE_CONTACT, {
                date: today.toISOString(),
                location: location,
                service_id: id,
            });
        } catch (error) {
            // console.log("ERROR ", error);
        }
        GAEventsTracker("Contact", `${cellphone}`);
        window.open(
            `https://wa.me/${cellphone}/?text=Hola%2C+vengo+desde+Kidssey+y+quisiera+obtener+m%C3%A1s+informaci%C3%B3n+sobre+sus+servicios.`
        );
    };
    return (
        <Card className={classes.root}>
            {/* <CardHeader
                avatar={<img src={avatar} alt="" className={classes.icon} />}
                title={name}
                subheader={address}
            /> */}
            <CardContent>
                <p className={classes.text1}>Descripción:</p>
                <p className={classes.text2}>{description}</p>
            </CardContent>

            {/* <CardContent>
                <div className={classes.twoColumns}>
                    <div className={classes.div2}>
                        <GeneralRatingStars value={score} />
                        {distance && (
                            <Typography variant="body2" color="textSecondary">{`A ${distance} km de ti`}</Typography>
                        )}
                    </div>
                    <div className={classes.div2}>
                        <TagsCard tags={tags} />
                    </div>
                </div>
            </CardContent> */}

            <div className={classes.twoColumns}>
                {type && (
                    <CardContent>
                        <p className={classes.text1}>Tipo:</p>
                        <p className={classes.text2}>
                            {type === "private" ? "Privado" : type === "public" ? "Público" : null}
                        </p>
                    </CardContent>
                )}
                <CardContent>
                    <p className={classes.text1}>Precio:</p>
                    <RatingCoins value={price_range} />
                </CardContent>
            </div>

            <CardContent>
                <AgeRange age_range={age_range} />
            </CardContent>
            <CardContent>
                <p className={classes.text1}>Inclusividad:</p>
                <InlcusivityIcons inclusivity={inclusivity} />
            </CardContent>

            {email && email !== "" ? (
                <CardContent>
                    <p className={classes.text1}>Email:</p>
                    <p className={classes.text2}> {email}</p>
                </CardContent>
            ) : null}

            {web_page && (
                <CardContent>
                    <p className={classes.text1}>Página web:</p>
                    <p className={classes.text2}>{web_page}</p>
                </CardContent>
            )}

            <CardActions style={{ justifyContent: "center" }}>
                <Button variant="contained" className={classes.contactBtn} onClick={handleContact}>
                    Contactar
                </Button>
            </CardActions>
        </Card>
    );
}
