import Footer from "./components/Footer";
import AppRouter from "./routers/AppRouter";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-214120502-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname);

function App() {
    return (
        <div>
            <AppRouter />
            <Footer />
        </div>
    );
}

export default App;
