import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const StyledRating = withStyles({
    iconFilled: {
        color: "#ffbf00",
    },
})(Rating);

const RatingCoins = (props) => {
    const { value } = props;

    return (
        <div>
            <Tooltip
                TransitionComponent={Zoom}
                title={
                    value === 1
                        ? "Menos de $100.000/mes"
                        : value === 2
                        ? "Entre $100.000 y $300.000/mes"
                        : value === 3
                        ? "Entre $300.000 y $700.000/mes"
                        : value === 4
                        ? "Más de $700.000/mes"
                        : null
                }
            >
                <div style={{ width: "fit-content" }}>
                    <StyledRating
                        readOnly
                        name="customized-color"
                        value={value}
                        getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
                        precision={1}
                        icon={<MonetizationOnIcon fontSize="inherit" />}
                        max={4}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default RatingCoins;
