import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import blind from "../../images/blind.png";
import deaf from "../../images/deafness.png";
import mute from "../../images/mute.png";
import autistic from "../../images/ribbon.png";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-evenly",
    },
    icon: {
        width: 30,
        height: 30,
        // backgroundColor: "red",
        marginRight: 15,
    },
}));

const InlcusivityIcons = (props) => {
    const { inclusivity } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {inclusivity?.map((tag) => (
                <Tooltip
                    TransitionComponent={Zoom}
                    title={
                        tag === "blind"
                            ? "Discapacidad visual"
                            : tag === "deaf"
                            ? "Discapacidad auditiva"
                            : tag === "mute"
                            ? "Discapacidad del lenguaje"
                            : tag === "autistic"
                            ? "Transtorno del espectro autista"
                            : null
                    }
                >
                    <img
                        src={
                            tag === "blind"
                                ? blind
                                : tag === "deaf"
                                ? deaf
                                : tag === "mute"
                                ? mute
                                : tag === "autistic"
                                ? autistic
                                : null
                        }
                        alt={tag}
                        className={classes.icon}
                    />
                </Tooltip>
            ))}
        </div>
    );
};

export default InlcusivityIcons;
