export const FIND_SERVICE_BY_ID = "/find-service-by-id";
export const FIND_SERVICE_BY_NAME = "/find-service-by-name";
export const FIND_SERVICE_BY_TAGS = "/find-service-by-tags";
export const FIND_SERVICE_BY_AGE_RANGE = "/find-service-by-age-range";
export const FIND_SERVICE_BY_SCORE = "/find-service-by-score";
export const FIND_SERVICE_BY_INCLUSIVITY = "/find-service-by-inclusivity";
export const FIND_SERVICE_BY_PRICE_RANGE = "/find-service-by-price-range";
export const FIND_SERVICE_BY_LOCATION = "/find-service-by-location";
export const FIND_SERVICE_BY_TYPE = "/find-service-by-type";
export const FILTER_SERVICES = "/service";
export const CREATE_CONTACT = "/create-contact";
