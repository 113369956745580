import React, { useState } from "react";
import styles from "./styles";
import girl_home from "../../images/girl_home.png";
import girl_home_mobile from "../../images/girl_home_mobile.png";
import logo_home from "../../images/logo_home.png";
import ButtonHome from "../../components/ButtonHome";
import PlacesSearchBar from "../../components/PlacesSearchBar";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Hidden } from "@material-ui/core";
import useGAEventsTracker from "../../hooks/useGAEventsTracker";

const Home = () => {
    const GAEventsTracker = useGAEventsTracker("Home Screen");

    let history = useHistory();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const [selected, setSelected] = useState({
        school: false,
        arts: false,
        sports: false,
    });
    const [requestLoading, setRequestLoading] = useState(false);

    const classes = styles();

    const handleFilter = (target) => {
        setSelected({
            ...selected,
            [target]: !selected[target],
        });
    };

    const getSelectedFilters = (obj) => {
        let result = "";
        for (let i in obj) {
            if (obj[i] === true) {
                if (result === "") {
                    result = i;
                } else {
                    result += `/${i}`;
                }
            }
        }
        return result;
    };

    const handleSearch = async (coordinates) => {
        if (coordinates.latitude && coordinates.longitude) {
            const selectedFilters = getSelectedFilters(selected);
            let url = "";
            if (selectedFilters.length === 0) {
                url = `/results/home/all/${coordinates.longitude}/${coordinates.latitude}/10000/public-private`;
            } else {
                url = `/results/home/${selectedFilters.replaceAll("/", "-")}/${coordinates.longitude}/${
                    coordinates.latitude
                }/10000/public-private`;
            }
            GAEventsTracker("Search", url);
            history.push(url);
        }
    };

    return (
        <div className={matches ? classes.root : classes.rootSmall}>
            <div className={classes.logoContainer}>
                <img src={logo_home} alt="logo" style={{ width: 150 }} />
            </div>
            <div className={matches ? classes.container : classes.containerSmall}>
                <Hidden smDown>
                    <div className={classes.imageContainer}>
                        <img src={girl_home} alt="img" style={{ width: "100%" }} />
                    </div>
                </Hidden>
                <div className={matches ? classes.searchContainer : classes.searchContainerSmall}>
                    <div style={{ width: matches ? "80%" : "100%" }}>
                        <p className={matches ? classes.text1 : classes.text1Small}>
                            Encuentra los mejores servicios infantiles en un sólo lugar
                        </p>
                        {/* <p className={matches ? classes.text2 : classes.text2Small}>
                         
                        </p> */}
                        <Hidden smUp>
                            <div className={matches ? classes.imageContainer : classes.imageContainerSmall}>
                                <img src={girl_home_mobile} alt="Logo" style={{ width: "65%" }} />
                            </div>
                        </Hidden>
                        <PlacesSearchBar handleSearch={handleSearch} requestLoading={requestLoading} />
                        <div className={classes.divButtons}>
                            <ButtonHome
                                text={"Colegios"}
                                onClick={() => handleFilter("school")}
                                selected={selected.school}
                            />
                            <ButtonHome
                                text={"Centros deportivos"}
                                onClick={() => handleFilter("sports")}
                                selected={selected.sports}
                            />
                            <ButtonHome
                                text={"Escuelas de arte"}
                                onClick={() => handleFilter("arts")}
                                selected={selected.arts}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
