import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
import PlacesSearchBar from "../PlacesSearchBar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import logo_header_pc from "../../images/logo_header_pc.png";
import logo_green from "../../images/logo_green.png";

const useStyles = makeStyles((theme) => ({
    appBar: { backgroundColor: "#6E57F7" },
    grow: {
        flexGrow: 1,
        // backgroundColor: "red",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    titleLink: {
        textDecoration: "none",
    },
    title: {
        // display: "none",
        fontFamily: "Tinos, serif",
        color: "white",
        fontWeight: "bold",
        fontSize: 30,
        padding: "1%",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        width: "100%",
        // backgroundColor: "red",
    },
    titleSmall: {
        fontFamily: "Tinos, serif",
        color: "white",
        fontWeight: "bold",
        fontSize: 25,
        padding: "1%",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        width: "100%",
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));

export default function Header() {
    let history = useHistory();
    const classes = useStyles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const handleSearch = async (coordinates) => {
        if (coordinates.latitude && coordinates.longitude) {
            const screenName = window.location.pathname.split("/")[1];
            history.push(
                `/results/${screenName}/all/${coordinates.longitude}/${coordinates.latitude}/10000/public-private`
            );
        }
    };

    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <div style={{ width: "25%" }}>
                        <Link to="/" className={classes.titleLink}>
                            {matches ? (
                                <img src={logo_header_pc} alt="logo" style={{ width: 150 }} />
                            ) : (
                                <img src={logo_green} alt="logo" style={{ width: 50 }} />
                            )}
                        </Link>
                    </div>
                    <div className={classes.grow} />
                    <div style={{ width: matches ? "30%" : "75%" }}>
                        <PlacesSearchBar handleSearch={handleSearch} />
                    </div>
                    {matches ? <div className={classes.grow} /> : null}
                    {/* <div className={classes.grow} /> */}
                </Toolbar>
            </AppBar>
        </div>
    );
}
