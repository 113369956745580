import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "3%",
        marginBottom: "3%",
    },
    container: {
        width: "90%",
        display: "flex",
        alignItems: "strech",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    carrouselContainer: {
        flex: 2,
    },
    contactCardContainer: {
        flex: 1,
    },
    containerSmall: {
        width: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        // backgroundColor: "red",
        // flexWrap: "nowrap",
    },
    images: {
        width: "50%",
    },
    headerInfo: {
        width: "90%",
        paddingLeft: "1%",
        // backgroundColor: "red",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: "2%",
    },
    icon: {
        width: 100,
        height: 100,
        marginRight: "2%",
    },
    text1: {
        fontFamily: "Nunito",
        color: "#262A54",
        fontSize: 27,
        fontWeight: "bold",
    },
    distanceText: {
        fontFamily: "Roboto",
        fontSize: 13,
    },
    carouselShadow: {
        width: 850,
        height: 500,
        backgroundColor: "lightgray",
        borderRadius: 15,
    },
}));

export default styles;
