import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ServiceCard from "../../components/ServiceCard";
import styles from "./styles";
import { useSelector } from "react-redux";
import api from "../../config/services/api";
import { FILTER_SERVICES } from "../../config/services/requests";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../config/state";
import CheckBoxFilter from "../../components/CheckBoxFilter";
import SliderDistance from "../../components/SliderDistance";
import Header from "../../components/Header";
import Card from "@material-ui/core/Card";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useGAEventsTracker from "../../hooks/useGAEventsTracker";

const Results = () => {
    const { screen, category, longitude, latitude, maxDistance, type, ageRange, priceRange, score, inclusivity } =
        useParams();
    const classes = styles();
    const history = useHistory();
    const GAEventsTracker = useGAEventsTracker("Results Screen");

    const resultsState = useSelector((state) => state.results?.results);
    const dispatch = useDispatch();
    const { setResults, setSelectedResult } = bindActionCreators(actionCreators, dispatch);

    const [data, setData] = useState(resultsState);
    const [loading, setLoading] = useState(false);
    const [filterSmall, setFilterSmall] = useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    // CHANGES ON URL PARAMS
    useEffect(() => {
        const source = axios.CancelToken.source();
        let mounted = true;

        const getURLData = async () => {
            setLoading(true);

            let filters = {
                tags: category ? (category === "all" ? "null" : category.replaceAll("-", "/")) : "null",
                location: `${longitude}/${latitude}`,
                max_distance: maxDistance ? maxDistance : "null",
                age_range: ageRange ? ageRange.replaceAll("-", "/") : "null",
                price_range: priceRange ? priceRange.replaceAll("-", "/") : "null",
                score: score ? score.replaceAll("-", "/") : "null",
                inclusivity: inclusivity ? inclusivity.replaceAll("-", "/") : "null",
                type: type ? type.replaceAll("-", "/") : "null",
                screen: screen ? screen : "null",
            };

            if (filters.score !== "null") {
                const selectedScores = filters.score.split`/`.map((x) => +x);
                const maxScore = Math.max(...selectedScores);
                filters = {
                    ...filters,
                    score: `${maxScore}`,
                };
            }

            const selectedFilters = Object.keys(filters).filter((k) => filters[k] !== "null");

            let url = selectedFilters.reduce((acc, cur) => {
                return acc + `&${cur}=${filters[cur]}`;
            }, "");
            url = url.replace("&", "?").replaceAll("_", "-");

            if (url) {
                try {
                    const res = await api.get(`${FILTER_SERVICES}${url}`, {
                        cancelToken: source.token,
                    });
                    // console.log("RES ", res.data);
                    setData(res.data);
                    setResults(res.data);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    // console.log("ERR ", error);
                }
            }
            setLoading(false);
        };

        if (mounted) {
            getURLData();
        }

        return function cleanup() {
            mounted = false;
            source.cancel();
        };
    }, [category, latitude, longitude, maxDistance, type, ageRange, priceRange, score, inclusivity]);

    const handleSelectService = (id) => {
        const selected = data.find((item) => item._id === id);
        if (selected) {
            setSelectedResult(selected);
        }
    };

    const handleFilterSelection = (filterName, data) => {
        let filters = {
            category: category,
            maxDistance: maxDistance ? maxDistance : "10000",
            type: type ? type : null,
            ageRange: ageRange ? ageRange : null,
            priceRange: priceRange ? priceRange : null,
            score: score ? score : null,
            inclusivity: inclusivity ? inclusivity : null,
        };
        filters = {
            ...filters,
            [filterName]: data ? data : filterName === "category" ? "all" : null,
        };
        let url = `/results/results/${filters.category}/${longitude}/${latitude}/${filters.maxDistance}/${filters.type}/${filters.ageRange}/${filters.priceRange}/${filters.score}/${filters.inclusivity}`;

        GAEventsTracker("Filter", url);
        history.push(url);
    };

    return (
        <div>
            <Header />
            <div className={matches ? classes.root : classes.rootSmall}>
                <Hidden smUp>
                    <Button
                        variant="contained"
                        className={classes.FilterBtnSmall}
                        onClick={() => setFilterSmall(!filterSmall)}
                    >
                        Filtrar
                    </Button>
                </Hidden>
                <Hidden smDown>
                    <Card className={classes.divFilters}>
                        <CheckBoxFilter
                            title={"Categorías"}
                            filterName={"category"}
                            data={[
                                {
                                    label: "Colegio",
                                    tag: "school",
                                    value:
                                        category !== undefined
                                            ? category === "all"
                                                ? true
                                                : category.split("-").includes("school")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Artes/Música",
                                    tag: "arts",
                                    value:
                                        category !== undefined
                                            ? category === "all"
                                                ? true
                                                : category.split("-").includes("arts")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Deportes",
                                    tag: "sports",
                                    value:
                                        category !== undefined
                                            ? category === "all"
                                                ? true
                                                : category.split("-").includes("sports")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Tipo de centro"}
                            filterName={"type"}
                            data={[
                                {
                                    label: "Público",
                                    tag: "public",
                                    value:
                                        type !== undefined
                                            ? type.split("-").includes("public")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Privado",
                                    tag: "private",
                                    value:
                                        type !== undefined
                                            ? type.split("-").includes("private")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <div className={classes.divSlider}>
                            <SliderDistance
                                filterName={"maxDistance"}
                                onCommit={handleFilterSelection}
                                initialValue={maxDistance}
                            />
                        </div>
                        <CheckBoxFilter
                            title={"Etapas"}
                            filterName={"ageRange"}
                            data={[
                                {
                                    label: "de 0 a 3 años",
                                    tag: "1",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("1")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "de 3 a 5 años",
                                    tag: "2",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("2")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "de 5 a 10 años",
                                    tag: "3",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("3")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "de 10 a 15 años",
                                    tag: "4",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("4")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Precio"}
                            filterName={"priceRange"}
                            priceFilter={true}
                            data={[
                                {
                                    label: "1",
                                    tag: "1",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("1")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "2",
                                    tag: "2",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("2")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "3",
                                    tag: "3",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("3")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "4",
                                    tag: "4",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("4")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Calificación"}
                            filterName={"score"}
                            scoreFilter={true}
                            data={[
                                {
                                    label: "1",
                                    tag: "1",
                                    value:
                                        score !== undefined ? (score.split("-").includes("1") ? true : false) : false,
                                },
                                {
                                    label: "2",
                                    tag: "2",
                                    value:
                                        score !== undefined ? (score.split("-").includes("2") ? true : false) : false,
                                },
                                {
                                    label: "3",
                                    tag: "3",
                                    value:
                                        score !== undefined ? (score.split("-").includes("3") ? true : false) : false,
                                },
                                {
                                    label: "4",
                                    tag: "4",
                                    value:
                                        score !== undefined ? (score.split("-").includes("4") ? true : false) : false,
                                },
                                {
                                    label: "5",
                                    tag: "5",
                                    value:
                                        score !== undefined ? (score.split("-").includes("5") ? true : false) : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Inclusividad"}
                            filterName={"inclusivity"}
                            data={[
                                {
                                    label: "Discapacidad visual",
                                    tag: "blind",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("blind")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Discapacidad auditiva",
                                    tag: "deaf",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("deaf")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Discapacidad del lenguaje",
                                    tag: "mute",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("mute")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Trastorno del espectro autista",
                                    tag: "autistic",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("autistic")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                    </Card>
                </Hidden>

                {filterSmall && !matches ? (
                    <Card className={classes.divFilters}>
                        <CheckBoxFilter
                            title={"Categorías"}
                            filterName={"category"}
                            data={[
                                {
                                    label: "Colegio",
                                    tag: "school",
                                    value:
                                        category !== undefined
                                            ? category === "all"
                                                ? true
                                                : category.split("-").includes("school")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Artes/Música",
                                    tag: "arts",
                                    value:
                                        category !== undefined
                                            ? category === "all"
                                                ? true
                                                : category.split("-").includes("arts")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Deportes",
                                    tag: "sports",
                                    value:
                                        category !== undefined
                                            ? category === "all"
                                                ? true
                                                : category.split("-").includes("sports")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Tipo de centro"}
                            filterName={"type"}
                            data={[
                                {
                                    label: "Público",
                                    tag: "public",
                                    value:
                                        type !== undefined
                                            ? type.split("-").includes("public")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Privado",
                                    tag: "private",
                                    value:
                                        type !== undefined
                                            ? type.split("-").includes("private")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <div className={classes.divSlider}>
                            <SliderDistance
                                filterName={"maxDistance"}
                                onCommit={handleFilterSelection}
                                initialValue={maxDistance}
                            />
                        </div>
                        <CheckBoxFilter
                            title={"Etapas"}
                            filterName={"ageRange"}
                            data={[
                                {
                                    label: "de 0 a 3 años",
                                    tag: "1",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("1")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "de 3 a 5 años",
                                    tag: "2",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("2")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "de 5 a 10 años",
                                    tag: "3",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("3")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "de 10 a 15 años",
                                    tag: "4",
                                    value:
                                        ageRange !== undefined
                                            ? ageRange.split("-").includes("4")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Precio"}
                            filterName={"priceRange"}
                            priceFilter={true}
                            data={[
                                {
                                    label: "1",
                                    tag: "1",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("1")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "2",
                                    tag: "2",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("2")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "3",
                                    tag: "3",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("3")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "4",
                                    tag: "4",
                                    value:
                                        priceRange !== undefined
                                            ? priceRange.split("-").includes("4")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Calificación"}
                            filterName={"score"}
                            scoreFilter={true}
                            data={[
                                {
                                    label: "1",
                                    tag: "1",
                                    value:
                                        score !== undefined ? (score.split("-").includes("1") ? true : false) : false,
                                },
                                {
                                    label: "2",
                                    tag: "2",
                                    value:
                                        score !== undefined ? (score.split("-").includes("2") ? true : false) : false,
                                },
                                {
                                    label: "3",
                                    tag: "3",
                                    value:
                                        score !== undefined ? (score.split("-").includes("3") ? true : false) : false,
                                },
                                {
                                    label: "4",
                                    tag: "4",
                                    value:
                                        score !== undefined ? (score.split("-").includes("4") ? true : false) : false,
                                },
                                {
                                    label: "5",
                                    tag: "5",
                                    value:
                                        score !== undefined ? (score.split("-").includes("5") ? true : false) : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                        <CheckBoxFilter
                            title={"Inclusividad"}
                            filterName={"inclusivity"}
                            data={[
                                {
                                    label: "Discapacidad visual",
                                    tag: "blind",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("blind")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Discapacidad auditiva",
                                    tag: "deaf",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("deaf")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Discapacidad del lenguaje",
                                    tag: "mute",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("mute")
                                                ? true
                                                : false
                                            : false,
                                },
                                {
                                    label: "Trastorno del espectro autista",
                                    tag: "autistic",
                                    value:
                                        inclusivity !== undefined
                                            ? inclusivity.split("-").includes("autistic")
                                                ? true
                                                : false
                                            : false,
                                },
                            ]}
                            onSelect={handleFilterSelection}
                        />
                    </Card>
                ) : loading ? (
                    <div className={matches ? classes.divNoResult : classes.divNoResultSmall}>
                        <CircularProgress size={50} thickness={5} className={classes.circularProgress} />
                    </div>
                ) : data && data.length > 0 ? (
                    <div className={classes.divCards}>
                        {data.map((item) => (
                            <ServiceCard
                                key={item._id}
                                id={item._id}
                                name={item.name}
                                description={item.description}
                                logo={item.logo}
                                score={item.score}
                                priceRange={item.price_range}
                                inclusivity={item.inclusivity}
                                otherCharacteristics={item.other_characteristics}
                                distance={item.distance}
                                cellphone={item.cellphone}
                                location={item.location}
                                handleSelection={handleSelectService}
                            />
                        ))}
                    </div>
                ) : (
                    <div className={matches ? classes.divNoResult : classes.divNoResultSmall}>
                        <p className={classes.noResultText}>Lo sentimos, no hay resultados para esta búsqueda.</p>
                        <p className={classes.noResultText}>Muy pronto tendremos resultados en tu zona.</p>
                    </div>
                )}

                {/* {loading ? (
                    <div className={classes.divNoResult}>
                        <CircularProgress size={50} thickness={5} className={classes.circularProgress} />
                    </div>
                ) : data && data.length > 0 ? (
                    <div className={classes.divCards}>
                        {data.map((item) => (
                            <ServiceCard
                                key={item._id}
                                id={item._id}
                                name={item.name}
                                description={item.description}
                                logo={item.logo}
                                score={item.score}
                                priceRange={item.price_range}
                                inclusivity={item.inclusivity}
                                otherCharacteristics={item.other_characteristics}
                                distance={item.distance}
                                cellphone={item.cellphone}
                                handleSelection={handleSelectService}
                            />
                        ))}
                    </div>
                ) : (
                    <div className={classes.divNoResult}>
                        <p>Lo sentimos, no hay resultados para esta búsqueda</p>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default Results;
