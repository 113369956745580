import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(() => ({
    root: {
        // backgroundImage: `url(${background1})`,
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "withe",
        // height: 750,
        widht: "100%",
        // padding: 100,
    },
    rootSmall: {
        // backgroundImage: `url(${background2})`,
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        height: 750,
        widht: "100%",
    },
    // textName: {
    //     fontFamily: "Tinos, serif",
    //     color: "white",
    //     fontWeight: "bold",
    //     fontSize: 30,
    //     padding: "1%",
    // },
    container: {
        // backgroundColor:"red",
        width: "100%",
        // height: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    containerSmall: {
        // backgroundColor: "red",
        width: "100%",
        height: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    searchContainer: {
        // backgroundColor:"blue",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "50%",
        height: "100%",
    },
    searchContainerSmall: {
        // backgroundColor:"blue",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        flexDirection: "column",
        width: "85%",
        height: "100%",
    },
    imageContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "40%",
        height: "100%",
    },
    imageContainerSmall: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // width: "50%",
    },
    text1: {
        fontFamily: "Nunito, sans-serif",
        fontWeight: 800,
        color: "#6E57F7",
        fontSize: 34,
    },
    // text2: {
    //     fontFamily: "Open Sans, sans-serif",
    //     color: "black",
    //     fontSize: 17,
    // },
    text1Small: {
        fontFamily: "Nunito, sans-serif",
        color: "#6E57F7",
        fontSize: 30,
        textAlign: "center",
        fontWeight: 800,
    },
    // text2Small: {
    //     fontFamily: "Open Sans, sans-serif",
    //     color: "black",
    //     fontSize: 13,
    //     fontWeight: "bold",
    //     textAlign: "center",
    // },
    divButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    logoContainer: {
        marginTop: 25,
        marginLeft: 25,
    },
}));

export default styles;
