import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
    },
    text1: {
        fontFamily: "Nunito",
        color: "#262A54",
        fontSize: 17,
        fontWeight: "bold",
    },
    separator: {
        width: "100%",
        border: "1px solid lightgray",
        alignSelf: "center",
        marginTop: theme.spacing(1.5),
    },
}));

const PrettoSlider = withStyles((theme) => ({
    root: {
        color: "#6E57F7",
        height: 8,
        // margin: theme.spacing(3),
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
}))(Slider);

const marks = [
    {
        value: 1,
        label: "1Km",
    },
    {
        value: 10,
        label: "10Km",
    },
    {
        value: 20,
        label: "20km",
    },
    {
        value: 30,
        label: "30km",
    },
    {
        value: 40,
        label: "40km",
    },
    {
        value: 50,
        label: "50km",
    },
];

function valuetext(value) {
    return `${value}°C`;
}

export default function SliderDistance(props) {
    const { filterName, onCommit, initialValue } = props;
    const classes = useStyles();

    const [value, setValue] = useState(parseInt(initialValue) / 1000);

    const handleChange = (e, val) => {
        setValue(val);
    };

    const handleChangeCommited = () => {
        const valueToSend = value * 1000;
        onCommit(filterName, valueToSend);
    };

    return (
        <div className={classes.root}>
            <Typography id="discrete-slider-restrict" gutterBottom className={classes.text1}>
                Distancia
            </Typography>
            <PrettoSlider
                value={value}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-restrict"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                max={50}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommited}
            />
            <div className={classes.separator}></div>
        </div>
    );
}
