import * as React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
// import InputAdornment from "@material-ui/core/InputAdornment";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import lupa from "../../images/lupa.png";
// import { InputUnstyled } from "@mui/core";
// import { useInput } from "@mui/base";
// import { styled } from "@mui/system";

// const StyledInputElement = styled("input")`
//     width: 100%;
//     font-size: 1rem;
//     font-family: IBM Plex Sans, sans-serif;
//     font-weight: 400;
//     line-height: 1.4375em;
//     border: 1px solid #bcbcbc;
//     border-radius: 30px;
//     padding: 10px 10px;
//     color: #20262d;

//     &:hover {
//         border: 3px solid #6e57f7;
//     }

//     &:focus {
//         outline: none;
//     }
// `;

const CssTextField = withStyles({
    root: {
        width: "100%",
        fontSize: "1rem",
        fontFamily: "Nunito",
        fontWeight: "regular",
        lineHeight: "1.4375em",
        border: " 1px solid #bcbcbc",
        borderRadius: 40,
        padding: "9px 0px 9px 20px",
        color: "#20262d",

        // &:hover {
        //     border: 3px solid #6e57f7,
        // }

        // &:focus {
        //     outline: none,
        // }
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        marginTop: "2%",
        marginBottom: "2%",
        // backgroundColor: "red",
    },
    margin: {
        width: "100%",
        // color: "red",
        // minHeight:90,
    },
    searchBtn: {
        backgroundColor: "#1AFCA5",
        color: "white",
        borderRadius: 50,
        padding: 10,
        margin: 0,
        height: 54,
        width: 81,
    },
    circularProgress: {
        color: "white",
    },
}));

// const CustomInput = React.forwardRef(function CustomInput(props, ref) {
//     const { getRootProps, getInputProps } = useInput(props, ref);

//     return (
//         <div style={{ width: "100%" }} {...getRootProps()}>
//             <StyledInputElement
//                 {...props}
//                 {...getInputProps()}
//                 endAdornment={<InputAdornment position="end">kg</InputAdornment>}
//             />
//             <div>hi</div>
//         </div>
//     );
// });

export default function SearchTextField(props) {
    const { value, onChange, placeholder, handleSearch, requestLoading } = props;
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate>
            <CssTextField
                value={value}
                onChange={onChange}
                className={classes.margin}
                placeholder={placeholder}
                InputLabelProps={{
                    style: { color: "black", fontFamily: "Nunito", fontWeight: "regular" },
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="contained"
                                className={classes.searchBtn}
                                onClick={handleSearch}
                                elevation={0}
                            >
                                {!requestLoading ? (
                                    <img src={lupa} alt="lupa" style={{ width: "40%" }} />
                                ) : (
                                    <CircularProgress size={20} thickness={4.5} className={classes.circularProgress} />
                                )}
                            </Button>
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
                variant="standard"
                margin="normal"
                autoComplete="off"
            />
            {/* <CustomInput
                value={value}
                onChange={onChange}
                className={classes.margin}
                placeholder={placeholder}
                InputLabelProps={{
                    style: { color: "#20262d" },
                }}
              
                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                autoComplete="off" */}
            {/* /> */}
        </form>
    );
}
