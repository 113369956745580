import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

// import PrivateRoute from "routers/PrivateRouter";
// import PublicRoute from "../PublicRoute";
import Home from "../../screens/Home";
import Results from "../../screens/Results";
import Service from "../../screens/Service";
import RouteChangeTracker from "../../components/RouteChangeTracker";

//Router o BrowserRouter al llamarlo estaremos creando un nuevo router
//Route se usa para crear las rutas
// "/" para apuntar a la URL raiz
//Switch para cargar sólamente el contenido de una ruta
//exact para entrar exactamente a esa ruta
// "*" para acceder a todas las citas
//link y  navlink para administrar menús de rutas

//las rutas deben ir de más especificas a general, por eso "/" debe ir de última

const AppRouter = () => {
    return (
        <Router>
            <RouteChangeTracker>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/results/:screen/:category/:longitude/:latitude/:maxDistance?/:type?/:ageRange?/:priceRange?/:score?/:inclusivity?">
                        <Results />
                    </Route>
                    <Route path="/service/:id">
                        <Service />
                    </Route>
                    <Redirect to={{ pathname: "/" }} />
                </Switch>
            </RouteChangeTracker>
        </Router>
    );
};

export default AppRouter;
