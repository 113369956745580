import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import GeneralRatingStars from "../GeneralRatingStars";
import RatingCoins from "../RatingCoins";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import InlcusivityIcons from "../InclusivityIcons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Hidden } from "@material-ui/core";
import useGAEventsTracker from "../../hooks/useGAEventsTracker";
import api from "../../config/services/api";
import { CREATE_CONTACT } from "../../config/services/requests";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        // width: "750",
        width: "75%",
        height: 200,
        padding: 15,
        position: "relative",
        marginBottom: 20,
    },
    rootSmall: {
        display: "flex",
        width: "100%",
        // height: 200,
        padding: 15,
        position: "relative",
        marginBottom: 20,
    },
    details: {
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 0 auto",
    },
    contentSmall: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
    },
    cover: {
        width: 150,
        height: 150,
        alignSelf: "center",
    },
    ratingContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    ratingSeparator: {
        marginLeft: 10,
        marginRight: 10,
    },
    contactBtn: {
        position: "absolute",
        top: "71%",
        left: "81.5%",
        backgroundColor: "#6E57F7",
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    contactBtnSmall: {
        width: "100%",
        backgroundColor: "#6E57F7",
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    nameLink: {
        textDecoration: "none",
        color: "black",
    },
    title: {
        fontFamily: "Nunito",
        fontWeight: "bold",
        fontSize: 20,
        color: "#262A54",
    },
    distanceText: {
        fontFamily: "Roboto",
        fontSize: 13,
    },
    descriptionText: {
        fontFamily: "Roboto",
        fontSize: 15,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    inclusivityIcons: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const ServiceCards = (props) => {
    const {
        id,
        name,
        description,
        logo,
        score,
        priceRange,
        inclusivity,
        otherCharacteristics,
        distance,
        cellphone,
        location,
        handleSelection,
    } = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const classes = useStyles(theme);
    const GAEventsTracker = useGAEventsTracker("Result Service Card");

    const handleContact = async () => {
        const today = new Date();
        try {
            api.post(CREATE_CONTACT, {
                date: today.toISOString(),
                location: location,
                service_id: id,
            });
        } catch (error) {
            // console.log("ERROR ", error);
        }
        GAEventsTracker("Contact", `${cellphone}`);
        window.open(
            `https://wa.me/${cellphone}/?text=Hola%2C+vengo+desde+Kidssey+y+quisiera+obtener+m%C3%A1s+informaci%C3%B3n+sobre+sus+servicios.`
        );
    };

    const calculateDistance = () => {
        let dist = distance / 1000;
        dist = dist.toFixed(2);
        return <Typography color="textSecondary" className={classes.distanceText}>{`A ${dist} km de ti`}</Typography>;
    };

    return (
        <Card className={matches ? classes.root : classes.rootSmall}>
            <Hidden smDown>
                <CardMedia className={classes.cover} image={logo} />
            </Hidden>
            <div className={classes.details}>
                <Hidden smUp>
                    <img className={classes.cover} src={logo} alt="" />
                </Hidden>
                <CardContent className={matches ? classes.content : classes.contentSmall}>
                    <Link to={`/service/${id}`} className={classes.nameLink} onClick={() => handleSelection(id)}>
                        <Typography className={classes.title}>{name}</Typography>
                    </Link>
                    <div className={classes.ratingContainer}>
                        <GeneralRatingStars value={score} />
                        <Typography variant="body2" color="textSecondary" className={classes.ratingSeparator}>
                            -
                        </Typography>
                        {calculateDistance()}
                    </div>
                    <Typography className={classes.descriptionText}>{description}</Typography>
                    {inclusivity && (
                        <div className={classes.inclusivityIcons}>
                            <InlcusivityIcons inclusivity={inclusivity} />
                        </div>
                    )}
                    <RatingCoins value={priceRange} />
                </CardContent>
                <Hidden smUp>
                    <Button variant="contained" className={classes.contactBtnSmall} onClick={handleContact}>
                        Contactar
                    </Button>
                </Hidden>
            </div>
            <Hidden smDown>
                <Button variant="contained" className={classes.contactBtn} onClick={handleContact}>
                    Contactar
                </Button>
            </Hidden>
        </Card>
    );
};

export default ServiceCards;

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import GeneralRatingStars from "../GeneralRatingStars"
// import ExploreIcon from '@material-ui/icons/Explore';
// import RatingCoins from '../RatingCoins';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     maxWidth: 280,
//   },
//   media: {
//     height: 0,
//     paddingTop: '56.25%', // 16:9
//   },
//   noMedia: {
//     height: "300px",
//     paddingTop: '56.25%', // 16:9
//   },
//   expand: {
//     transform: 'rotate(0deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//       duration: theme.transitions.duration.shortest,
//     }),
//   },
//   expandOpen: {
//     transform: 'rotate(180deg)',
//   },
//   avatar: {
//     width: 60
//   },
//   DistanceDiv:{
//     display:"flex",
//       // backgroundColor:"red",
//     fontSize:12,
//     alignItems:"center"
//   },
//   divHeader:{
//     // backgroundColor:"blueviolet",
//     paddingRight:"10%"
//   }

// }));

// export default function ServiceCard(props) {
//   const {image,logo} = props
//   const classes = useStyles();
//   const [expanded, setExpanded] = React.useState(false);

//   const handleExpandClick = () => {
//     setExpanded(!expanded);
//   };

//   return (
//     <Card className={classes.root}>
//       <CardHeader
//         avatar={<img src={logo} alt="Logo" className={classes.avatar } />
//         }
//         // action={
//         //   <IconButton aria-label="settings">
//         //     <MoreVertIcon />
//         //   </IconButton>
//         // }
//         className={classes.divHeader}
//         title={<div><p>Institución Educativa Departamental Antonio Ricaurte</p></div>}
//         subheader={<div><div className={classes.DistanceDiv}><ExploreIcon fontSize="small" /><p>Distancia: 4.5Km</p>  </div><GeneralRatingStars /></div>}
//       />
//       <CardMedia
//         className={image==="notImage"? classes.noMedia: classes.media}
//         image={image==="notImage"? logo: image}
//         title="Imágenes"
//       />
//       <CardContent>
//         <Typography variant="body2" color="textSecondary" component="p">
//       Colegio público de educación secundaria y bachillerato
//         </Typography>
//       </CardContent>
//       <CardContent>
//       <RatingCoins />
//       </CardContent>
//       <CardActions disableSpacing>
//         <IconButton aria-label="add to favorites">
//           <FavoriteIcon />
//         </IconButton>
//         <IconButton aria-label="share">
//           <ShareIcon />
//         </IconButton>
//         <IconButton
//           className={clsx(classes.expand, {
//             [classes.expandOpen]: expanded,
//           })}
//           onClick={handleExpandClick}
//           aria-expanded={expanded}
//           aria-label="show more"
//         >
//           <ExpandMoreIcon />
//         </IconButton>
//       </CardActions>

//     </Card>
//   );
// }
